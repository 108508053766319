.dropdown-button {
  @apply text-14 font-medium border border-black-30 hover:border-black bg-white hover:bg-black-10 py-12 px-16 rounded-full flex gap-6 justify-between items-center whitespace-nowrap focus:outline-none focus-visible:outline-dark transition-all;
}

.dropdown-button--toggle {
  @apply rounded-full p-12 justify-center min-h-[44px] min-w-[44px] max-h-[44px] max-w-[44px];
}

.dropdown-button--toggle-active {
  @apply border-black bg-black-10;
}

.dropdown-button svg {
  @apply transform;
}

.dropdown-button--active {
  @apply bg-black-10 border-black;
}

.dropdown-button--active svg {
  @apply rotate-180;
}

.dropdown-menu-container {
  @apply absolute bg-white rounded-xl shadow-1 p-12 z-40 mt-10 hidden;
}

.dropdown-menu-container--active {
  @apply block;
}

.dropdown-item {
  @apply w-full border-t border-black-30 first:border-t-0;
}

.dropdown-item button,
.dropdown-item a {
  @apply focus:outline-none focus-visible:outline-dark focus:text-black-30 focus:bg-tan w-full text-14 p-8 pl-12 my-8 rounded-lg text-left whitespace-nowrap hover:text-black hover:bg-pale aria-selected:bg-tan;
}

.dropdown-reset {
  @apply button--secondary p-12 rounded-xl border border-black-30 text-14 w-full;
}
