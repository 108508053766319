.logo-sm,
.logo-lg {
  @apply h-auto w-auto;
}

@layer utilities {
  .logo-sm {
    @apply max-h-24 max-w-108;
  }

  .logo-lg {
    @apply max-h-36 max-w-132;
  }
}
