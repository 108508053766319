/* Card */
.ex-map-item {
  @apply rounded-[10px] tablet:rounded-[20px] bg-white border border-black text-center col-span-3 tablet:col-span-4;
}

/* Desktop animation */
@screen desktop {
  .ex-map-item {
    @apply w-[15.2%] absolute;
  }

  /* Item 1 */
  .ex-map-item--1 {
    @apply z-[7];
    left: calc(50% - 7.6%);
  }

  .linear-map--active .ex-map-item--1 {
    animation-name: ex-map-item--1;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--1 {
    0% {
      left: calc(50% - 7.6%);
      top: 0;
    }
    100% {
      @apply left-0;
      top: 90px;
    }
  }

  /* Item 2 */
  .ex-map-item--2 {
    @apply z-[6];
    left: calc(50% - 7.6%);
    transform: translate(-10px, -5px);
  }
  .linear-map--active .ex-map-item--2 {
    animation-name: ex-map-item--2;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--2 {
    0% {
      left: calc(50% - 7.6%);
      transform: translate(-10px, -5px);
    }
    100% {
      /* 1 card + gutter */
      left: calc(15.2% + 24px);
      transform: translate(0, 0);
    }
  }

  /* Item 3 */
  .ex-map-item--3 {
    @apply z-[5];
    left: calc(50% - 7.6%);
    transform: translate(10px, 10px);
  }
  .linear-map--active .ex-map-item--3 {
    animation-name: ex-map-item--3;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--3 {
    0% {
      left: calc(50% - 7.6%);
      top: 0;
      transform: translate(10px, 10px);
    }
    100% {
      /* 1 and 1/2 cards + 1 and 1/2 half gutter */
      left: calc(22.8% + 36px);
      top: 190px;
      transform: translate(0, 0);
    }
  }

  /* Item 4 */
  .ex-map-item--4 {
    @apply z-[4];
    left: calc(50% - 7.6%);
    transform: translate(-20px, 20px);
  }
  .linear-map--active .ex-map-item--4 {
    animation-name: ex-map-item--4;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--4 {
    0% {
      top: 0;
      transform: translate(-20px, 20px);
    }
    100% {
      top: 140px;
      transform: translate(0, 0);
    }
  }

  /* Item 5 */
  .ex-map-item--5 {
    @apply z-[3];
    right: calc(50% - 7.6%);
    transform: translate(20px, -20px);
  }
  .linear-map--active .ex-map-item--5 {
    animation-name: ex-map-item--5;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--5 {
    0% {
      right: calc(50% - 7.6%);
      top: 0;
      transform: translate(20px, -20px);
    }
    100% {
      /* 1 and 1/2 cards + 1 and 1/2 half gutter */
      right: calc(22.8% + 36px);
      top: 180px;
      transform: translate(0, 0);
    }
  }

  /* Item 6 */
  .ex-map-item--6 {
    @apply z-[2];
    right: calc(50% - 7.6%);
    transform: translate(30px, 0);
  }
  .linear-map--active .ex-map-item--6 {
    animation-name: ex-map-item--6;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--6 {
    0% {
      right: calc(50% - 7.6%);
      top: 0;
      transform: translate(30px, 0);
    }
    100% {
      /* 1 card + gutter */
      right: calc(15.2% + 24px);
      top: 20px;
      transform: translate(0, 0);
    }
  }

  /* Item 7 */
  .ex-map-item--7 {
    @apply z-[1];
    right: calc(50% - 7.6%);
    transform: translate(-30px, 0);
  }
  .linear-map--active .ex-map-item--7 {
    animation-name: ex-map-item--7;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.85, -0.19, 0.33, 1.21);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes ex-map-item--7 {
    0% {
      right: calc(50% - 7.6%);
      top: 0;
      transform: translate(-30px, 0);
    }
    100% {
      right: 0;
      top: 160px;
      transform: translate(0, 0);
    }
  }

  /* SVGs */
  .linear-map--active .ex-map-item-list svg {
    @apply opacity-100;
  }

  .ex-map-item svg {
    @apply opacity-0 transition-opacity delay-[1s] duration-1000;
  }
}

/* Center last card on mobile */
@screen mobileOnly {
  .ex-map-item--7 {
    @apply mx-auto w-1/2;
    grid-column: inherit;
  }
}
