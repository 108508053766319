.product-accordion__details[open] {
  @apply border-purple-400;
}

.product-accordion__summary {
  @apply list-none;
}

.product-accordion__summary::-webkit-details-marker,
.product-accordion__summary::marker {
  @apply hidden;
}

.product-accordion__details[open] .product-accordion__summary svg {
  @apply first-of-type:hidden;
}

.product-accordion__details[open] .product-accordion__summary svg {
  @apply last-of-type:block;
}
