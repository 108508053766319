/* ~6-12 letter words (black) */
.camper-underline {
  background-image: url("media/camper-underlines/underline.svg");
  background-position: bottom 0.12em center;
  background-size: 100% 0.2em;
  @apply bg-no-repeat;
}

/* ~6 letter words or less (black)  */
.camper-underline--short {
  background-image: url("media/camper-underlines/underline--short.svg");
}

/* > 12 letter words (black) */
.camper-underline--long {
  background-image: url("media/camper-underlines/underline--long.svg");
  background-position: bottom 0.1em center;
}

/* Add this 'white-camper-underlines' class to a parent element to set underlines in white */
.white-camper-underlines .camper-underline {
  background-image: url("media/camper-underlines/underline--white.svg");
}

.white-camper-underlines .camper-underline--short {
  background-image: url("media/camper-underlines/underline--short-white.svg");
}

.white-camper-underlines .camper-underline--long {
  background-image: url("media/camper-underlines/underline--long-white.svg");
}
