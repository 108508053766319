@layer utilities {
  /* Headings */

  .heading-xl {
    @apply text-40 tablet:text-60 desktop:text-84;
  }

  .heading-lg {
    @apply text-32 tablet:text-48 desktop:text-60;
  }

  .heading-md {
    @apply text-32 tablet:text-40 desktop:text-48;
  }

  .heading-sm {
    @apply text-24 tablet:text-32 desktop:text-40;
  }

  .heading-xs {
    @apply text-24 tablet:text-32;
  }

  .heading-xxs {
    @apply text-16 tablet:text-24;
  }

  /* Text */

  .text-lg {
    @apply text-16 tablet:text-20;
  }

  .text-md {
    @apply text-14 tablet:text-16 desktop:text-20;
  }

  .text-sm {
    @apply text-14 desktop:text-16;
  }

  .text-xs {
    @apply text-12 desktop:text-14;
  }
}

/* Eyebrow label */

.eyebrow {
  @apply uppercase tracking-[0.12em] text-10 font-semibold text-black-70;
}

/* Used on dark colour backgrounds */
.eyebrow--light {
  @apply text-white;
}
