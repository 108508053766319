/* TODO: This should all be removed at some point with the refresh work */

/* Rich text components in Body fields */

.rich-text-component {
  @apply pb-36;
}

/* Offset page top padding for Hero component with background color at top of page */

.component--hero-with-bg {
  @apply first:-mt-36;
}

@screen tablet {
  .component--hero-with-bg {
    @apply first:-mt-48;
  }
}

@screen desktop {
  .component--hero-with-bg {
    @apply first:-mt-60;
  }
}
