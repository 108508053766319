.ex-map-flip-card {
  @apply w-full h-[125px] tablet:h-[215px] desktop:h-[240px] hover:cursor-pointer transition;
  perspective: 1000px;
}

.ex-map-flip-card__content {
  @apply h-full w-full rounded-[20px];
  transition: all 0.3s ease;
  transform-style: preserve-3d;
}

.ex-map-flip-card--open .ex-map-flip-card__content {
  transform: rotateY(180deg);
}

.ex-map-flip-card__content:hover {
  box-shadow: 8px 8px 0px 0px #ffb08a;
}

.ex-map-flip-card--open .ex-map-flip-card__content:hover {
  box-shadow: -8px 8px 0px 0px #ffb08a;
}

.ex-map-flip-card__front,
.ex-map-flip-card__back {
  @apply absolute w-full h-full border border-black px-12 tablet:px-24 py-16 tablet:py-[18px] rounded-[20px] bg-purple-200;
  backface-visibility: hidden;
}

.ex-map-flip-card__front {
  @apply bg-orange-400;
}

.ex-map-flip-card__front--alt {
  @apply bg-yellow-400;
}

.ex-map-flip-card__back {
  @apply bg-pale text-center;
  transform: rotateY(180deg);
}
