@import "flash/index.css";

.survey-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.survey-content {
  flex: 1 0 auto;
}

.survey__home-image {
  background-image: url("media/campaigns/hrforhr/home@2x.webp");
}

/* @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .survey__home-image {
    background-image: url("media/hrforhr/home@2x.jpg");
  }
} */

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__home-image {
    min-height: 20rem;
  }
}

@media (max-width: 480px) {
  .survey__home-image {
    min-height: 20rem;
  }
}

.survey__start-image {
  background-image: url("media/campaigns/hrforhr/start@2x.webp");
}

/* @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .survey__start-image {
    background-image: url("media/hrforhr/start@2x.webp");
  }
} */

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__start-image {
    min-height: 20rem;
  }
}

@media (max-width: 480px) {
  .survey__start-image {
    min-height: 20rem;
  }
}

.survey__masthead {
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.02),
    0 2px 6px 0 rgba(0, 0, 0, 0.02);
}

.survey__masthead h1 {
  padding-top: 0;
}

.survey__label-wrapper {
  width: 20%;
  cursor: pointer;
  padding-right: 0.3125rem;
  width: 36px;
  height: 30px;
}

@screen tablet {
  .survey__label-wrapper {
    width: 20%;
    cursor: pointer;
    padding-right: 0.3125rem;
    width: 44px;
    height: 35px;
  }
}

.survey__label-wrapper:last-of-type {
  padding-right: 0;
}

.survey__scale-label {
  opacity: 0;
}

.survey__label-group--selected-undefined .survey__scale-label {
  opacity: 1;
}

.survey__radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.survey__label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-top: -2.2rem;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  @apply font-semibold;
  @apply text-14;
}

.survey__label-wrapper:focus .survey__label,
.survey__label-wrapper:hover .survey__label {
  opacity: 1;
  pointer-events: auto;
}

.survey__selector-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.survey__radio:focus ~ .survey__selector-wrapper {
  box-shadow: 0 0 0 3px rgba(194, 134, 209, 0.5);
}

.survey__selector {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @apply border-2;
  @apply border-black-30;
  @apply bg-transparent;
}

.survey__label-group--selected-1
  .survey__label-wrapper:first-of-type
  .survey__selector,
.survey__label-group--selected-2
  .survey__label-wrapper:first-of-type
  .survey__selector,
.survey__label-group--selected-2
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-3
  .survey__label-wrapper:first-of-type
  .survey__selector,
.survey__label-group--selected-3
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-3
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:first-of-type
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(4)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:first-of-type
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(4)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(5)
  .survey__selector,
.survey__label-wrapper:focus .survey__selector,
.survey__label-wrapper:hover .survey__selector {
  @apply bg-[#F5EBF7];
  @apply border-black;
}

.survey__label-group--selected-2
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-3
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(2)
  .survey__selector,
.survey__label-wrapper:nth-of-type(2):focus .survey__selector,
.survey__label-wrapper:nth-of-type(2):hover .survey__selector {
  @apply bg-[#E1C3E8];
}

.survey__label-group--selected-3
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(3)
  .survey__selector,
.survey__label-wrapper:nth-of-type(3):focus .survey__selector,
.survey__label-wrapper:nth-of-type(3):hover .survey__selector {
  @apply bg-[#C286D1];
}

.survey__label-group--selected-4
  .survey__label-wrapper:nth-of-type(4)
  .survey__selector,
.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(4)
  .survey__selector,
.survey__label-wrapper:nth-of-type(4):focus .survey__selector,
.survey__label-wrapper:nth-of-type(4):hover .survey__selector {
  @apply bg-[#AE5EC1];
}

.survey__label-group--selected-5
  .survey__label-wrapper:nth-of-type(5)
  .survey__selector,
.survey__label-wrapper:last-of-type:focus .survey__selector,
.survey__label-wrapper:last-of-type:hover .survey__selector {
  @apply bg-purple-400;
}

.survey__label-wrapper:first-of-type .survey__selector {
  border-top-left-radius: 62.4375rem;
  border-bottom-left-radius: 62.4375rem;
}

.survey__label-wrapper:last-of-type .survey__selector {
  border-top-right-radius: 62.4375rem;
  border-bottom-right-radius: 62.4375rem;
}

.survey__progress-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 62.4375rem;
  margin-top: 0.375rem;
  border-width: 0.125rem;
}

/* Still used */

.survey__progress-line {
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 2px;
  overflow: hidden;
  top: 7px;
  left: 30px;
  width: 20px;
}

@screen tablet {
  .survey__progress-line {
    top: 33px;
    left: 54px;
    width: 51px;
  }
}

.survey__progress-line-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @apply bg-purple-400;
}

/* End of still used */

.survey__footer {
  flex-shrink: 0;
  box-shadow:
    0 -2px 6px 0 rgba(0, 0, 0, 0.02),
    0 -2px 6px 0 rgba(0, 0, 0, 0.02);
}

.survey__finish-button {
  background-color: #0168b3;
  background-color: rgba(1, 104, 179, 1);
  border-color: #0168b3;
  border-color: rgba(1, 104, 179, 1);
  color: #fff;
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__finish-button {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 1);

    border-color: #99c3e1;
    border-color: rgba(153, 195, 225, 1);

    color: #0168b3;
    color: rgba(1, 104, 179, 1);
  }
}

@media (max-width: 480px) {
  .survey__finish-button {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 1);

    border-color: #99c3e1;
    border-color: rgba(153, 195, 225, 1);

    color: #0168b3;
    color: rgba(1, 104, 179, 1);
  }
}

.survey__finish-button:focus,
.survey__finish-button:hover {
  background-color: #00518c;
  border-color: #00518c;
}

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__finish-button:focus,
  .survey__finish-button:hover {
    border-color: #0168b3;
    border-color: rgba(1, 104, 179, 1);

    background-color: #e6f0f7;
    background-color: rgba(230, 240, 247, 1);
  }
}

@media (max-width: 480px) {
  .survey__finish-button:focus,
  .survey__finish-button:hover {
    border-color: #0168b3;
    border-color: rgba(1, 104, 179, 1);

    background-color: #e6f0f7;
    background-color: rgba(230, 240, 247, 1);
  }
}

.survey__link:focus,
.survey__link:hover {
  color: #00518c;
}

.survey__share-text {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.copy-success .survey__share-text {
  background-color: #ffffc7;
}

.survey__copy-tooltip {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: -3.5rem;
  background-color: #35374a;
  background-color: rgba(53, 55, 74, 1);
  padding: 0.5rem;
  border-radius: 0.4375rem;
  font-size: 0.875rem;
  color: #fff;
  color: rgba(255, 255, 255, 1);
  margin-left: -2.5rem;
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  --transform-translate-y: 0.375rem;
  left: 50%;
  width: 80px;
}

.copy-success .survey__copy-tooltip {
  opacity: 1;
  pointer-events: auto;
  --transform-translate-y: 0;
}

.survey__copy-tooltip:after {
  color: #35374a;
  color: rgba(53, 55, 74, 1);
  position: absolute;
  bottom: -0.375rem;
  margin-left: -0.375rem;
  width: 0;
  height: 0;
  content: "";
  height: 0;
  left: 50%;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
}

.survey__intro {
  background-image: linear-gradient(180deg, #fffae8 0, #fff5d2);
}

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__intro-image {
    min-height: 20rem;
  }
}

@media (max-width: 480px) {
  .survey__intro-image {
    min-height: 20rem;
  }
}

.survey__aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1399px) {
  .survey__question h2 {
    height: 8rem;
  }
  .survey__aligner {
    height: 55.5vh;
  }
}

@media (min-width: 1024px) and (max-width: 1398px) {
  .survey__question h2 {
    height: 8rem;
  }
  .survey__aligner {
    height: 55.5vh;
  }
}

@media (min-width: 481px) and (max-width: 1023px) {
  .survey__question h2 {
    height: 8rem;
  }
  .survey__aligner {
    height: 55.5vh;
  }
}

@media (max-width: 480px) {
  .survey__aligner {
    height: 63vh;
  }
}

.survey__list ul {
  list-style-type: disc;
  margin-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.survey__list li {
  padding-bottom: 0.75rem;
}

.survey__face {
  background-color: #fff5d2;
  background-color: rgba(255, 245, 210, 1);
}

.survey__face,
.survey__face:focus,
.survey__face:hover {
  color: #35374a;
  color: rgba(53, 55, 74, 1);
}

.survey__face:focus,
.survey__face:hover {
  background-color: #ffeba5;
  background-color: rgba(255, 235, 165, 1);
}

.survey__face--active,
.survey__face--active:focus,
.survey__face--active:hover {
  background-color: #35374a;
  background-color: rgba(53, 55, 74, 1);
  color: #fff5d2;
  color: rgba(255, 245, 210, 1);
}

.survey__face svg {
  top: 50%;
  left: 50%;
  pointer-events: none;
}

.survey__feedback {
  display: none;
}

.survey__feedback--active {
  display: block;
}

.survey__inspiration:first-of-type a {
  padding-top: 0;
}

.survey__inspiration:last-of-type a {
  padding-bottom: 0;
}

.survey__inspiration:last-of-type {
  border-bottom: none;
}

.survey__checkbox:checked {
  @apply bg-purple-400;
}

.survey__checkbox:checked:after {
  color: #fff;
  color: rgba(255, 255, 255, 1);
  font-size: 1rem;
  font-weight: 800;
  content: "✓";
}

.survey__result__label {
}

.survey__result__radio {
  @apply border-2;
  @apply border-black;
  @apply rounded-full;
  @apply bg-white;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 0.75rem;
  cursor: pointer;
  position: relative;
}

.survey__result__radio:after {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0.25rem;
  @apply bg-black;
  @apply rounded-full;
  display: none;
  content: "";
}

.survey__result__radio:checked:after {
  display: block;
}

.survey__result__explore {
  opacity: 0;
}

.survey__result__label:focus .survey__result__explore,
.survey__result__label:hover .survey__result__explore {
  opacity: 1;
}

.summary-rating {
  width: 0.375rem;
  height: 0.75rem;
  border-radius: 0.4375rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.inspiration--arrow {
  display: inline-block;
  transition-property: transform;
  transition-duration: 0.3s;
  transform: translateX(0.3rem);
}

.survey__inspiration a:hover .inspiration--arrow,
.survey__result__label a:hover .inspiration--arrow {
  transform: translateX(1rem);
}
