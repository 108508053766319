@font-face {
  font-family: "tiempos";
  font-weight: 300;
  font-display: swap;
  src:
    url("./core/fonts/tiempos/tiempos-headline-web-light.woff2") format("woff2"),
    url("./core/fonts/tiempos/tiempos-headline-web-light.woff") format("woff");
}

@font-face {
  font-family: "tiempos";
  font-weight: 500;
  font-display: swap;
  src:
    url("./core/fonts/tiempos/tiempos-headline-medium.woff2") format("woff2"),
    url("./core/fonts/tiempos/tiempos-headline-medium.woff") format("woff");
}

@font-face {
  font-family: "tiempos";
  font-weight: 600;
  font-display: swap;
  src:
    url("./core/fonts/tiempos/tiempos-headline-semibold.woff2") format("woff2"),
    url("./core/fonts/tiempos/tiempos-headline-semibold.woff") format("woff");
}
