.type-of-company-quiz-button input.focus-visible {
  @apply outline-purple-400;
}

.type-of-company-quiz-form select {
  @apply focus:outline-none focus:ring-2 focus:ring-black focus:ring-inset;
  -webkit-appearance: none;
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg height='9' viewBox='0 0 10 8' width='10' xmlns='http://www.w3.org/2000/svg' role='img' focusable='false' aria-hidden='aria_hidden'><path clip-rule='evenodd' d='m1.17917.844971 3.82083 3.901729 3.82083-3.901729 1.17917 1.204129-5 5.10587-5-5.10587z' fill-rule='evenodd'></path></svg>");
}

.quiz-tool-tip {
  @apply after:content-[""] after:absolute after:h-[14px] after:w-[14px] after:rotate-45 after:top-1/2 after:-left-8 after:border-purple-400 after:border-b after:border-l after:bg-purple-200 -mt-[7px];
}

/* Hubspot form style */
.hs-form-2cacbcbc-fa8b-4b31-b369-d133839e26bd_998c55f7-757f-4c41-8e46-f2f5939c5135
  fieldset {
  max-width: 100% !important;
}

.hubspot-quiz-form > fieldset {
  max-width: 100% !important;
}

.hubspot-quiz-form > fieldset > .hs_email > .input > input[name="email"] {
  width: 100% !important;
}
