.table-of-contents__list {
  @apply flex flex-col gap-12 border-l border-black-30;
}

.table-of-contents__list-item {
  @apply pl-12;
}

.table-of-contents__list-item.is-active-li {
  @apply border-l-2 border-purple-400 -ml-1;
}

.table-of-contents__link {
  @apply text-14;
}

.table-of-contents__active-link {
  @apply desktop:font-semibold;
}

/* Currently only used on the Case Study TOC*/
.table-of-contents__xl-list {
  @apply flex flex-col gap-20 desktop:gap-36;
}

.table-of-contents__xl-list-item {
  @apply desktop:pl-16;
}

.table-of-contents__xl-list-item.is-active-li {
  @apply desktop:border-l-2 desktop:border-purple-400 desktop:-ml-1 desktop:font-heading;
}

.table-of-contents__xl-link {
  @apply text-16 underline desktop:no-underline;
}

.table-of-contents__xl-active-link {
  @apply desktop:text-24;
}
