@layer utilities {
  .animate-slide-fade-in {
    animation-name: fadeIn;
    animation-timing-function: ease;
    animation-duration: 0.5s;
  }

  @keyframes fadeIn {
    from {
      transform: translate(15px, 0px);
      opacity: 0;
    }
    to {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
}
