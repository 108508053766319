.ex-map-full-height {
  min-height: calc(100vh - 53px);
}

@screen tablet {
  .ex-map-full-height {
    min-height: calc(100vh - 60px);
  }
}

@screen desktop {
  .ex-map-full-height {
    min-height: calc(100vh - 65px);
  }
}

/* Factors item animation */

.factors-animation-item {
  @apply opacity-0 transition-opacity duration-1000;
}

.workplace-factors--active .factors-animation-item,
.external-factors--active .factors-animation-item {
  @apply opacity-100;
}

/* Orange cards */
.workplace-factors--active .factors-animation-item--reveal {
  @apply delay-500;
}
.workplace-factors--active .factors-animation-item--work {
  @apply delay-300;
}
.workplace-factors--active .factors-animation-item--team {
  @apply delay-700;
}

/* Yellow cards*/
.external-factors--active .factors-animation-item--self {
  @apply delay-300;
}
.external-factors--active .factors-animation-item--shock-events {
  @apply delay-700;
}

/* Pills */

.ex-map-pills {
  @apply opacity-0 transition-opacity duration-1000;
}

.ex-map-pills--active {
  @apply opacity-100;
}

/* Details items */

.ex-map-details-item summary svg {
  @apply transition-transform;
}

.ex-map-details-item[open] summary svg {
  @apply rotate-45 duration-100;
}
