/* Defaults */

@layer components {
  /* Text link */

  .text-link {
    @apply underline underline-offset-4 transition-all hover:bg-black-10 break-words hyphens-auto;
  }

  /* Buttons */

  .button {
    /* 0.875rem = 14px */
    @apply px-24 py-[0.875rem] border rounded-full font-main font-medium text-14 leading-tight inline-flex items-center justify-center transition duration-300 ease-in-out w-auto gap-x-6;
    @apply disabled:opacity-25 disabled:pointer-events-none;
    @apply focus:outline-dark;
  }

  /* Primary */
  .button--primary {
    @apply border-black bg-black text-white;
    @apply hover:bg-black-70;
  }

  /* Primary reversed */
  .button--primary-reversed {
    @apply border-white bg-white text-black;
    @apply hover:bg-white-70;
    @apply focus:outline-light group-focus:outline-light;
  }

  /* Secondary */
  .button--secondary {
    @apply border-black text-black;
    @apply hover:bg-black-10;
  }

  /* Secondary reversed */
  .button--secondary-reversed {
    @apply border-white text-white;
    @apply hover:bg-white-10;
    @apply focus:outline-light group-focus:outline-light;
  }

  /* Text */
  .button--text,
  .button--text-reversed {
    @apply p-[14px] border-0 rounded-[10px] underline underline-offset-[3px] min-w-0;
  }

  .button--text {
    @apply hover:bg-black-10;
  }

  /* Text reversed */
  .button--text-reversed {
    @apply text-white;
    @apply hover:bg-white-10;
    @apply focus:outline-light group-focus:outline-light;
  }

  /* Full width modifier */

  .button--wide {
    @apply w-full;
  }

  /* Round modifier for icons */

  .button--round {
    @apply p-[0.7rem];
  }
}

/* NOTE: JS focus state. To review and hopefully remove */

.js-focus-visible .button:focus:not(.focus-visible) {
  @apply outline-none;
}
