.sales-toggle--open .sales-toggle__number {
  @apply border-b;
}

.sales-toggle--open .sales-toggle__trigger svg {
  @apply rotate-180;
}

.sales-toggle .sales-toggle__label > span span:after {
  content: " / ";
}

.sales-toggle .sales-toggle__label > span span:last-of-type:after {
  content: "";
}

.sales-toggle--open .sales-toggle__label > span:nth-of-type(2) {
  @apply hidden;
}

.sales-toggle--open .sales-toggle__label > span {
  @apply last-of-type:inline;
}

.sales-toggle--open .sales-toggle__dropdown {
  @apply block;
}

.sales-toggle__dropdown-inner {
  @apply before:content-[""] before:absolute before:-top-6 before:right-0 before:mr-8 before:w-12 before:h-12 before:rotate-45 before:bg-white before:border before:border-black;
}

.sales-toggle__dropdown-inner {
  @apply after:content-[""] after:absolute after:top-0 after:right-0 after:w-20 after:h-10 after:bg-white after:mr-2;
}

.sales-toggle__dropdown--static .sales-toggle__dropdown-inner {
  @apply before:hidden after:hidden;
}

.sales-toggle__dropdown-inner--reverse {
  @apply before:top-auto before:-bottom-6 before:border-0;
}
