:root {
  --mobile-nav-width: 375px;
}

@screen tablet {
  .masthead__component-library-menu {
    width: var(--mobile-nav-width);
  }
}

.mobile-menu-open .masthead__component-library-menu {
  @apply block bg-pale h-[100vh];
}

.mobile-menu-open .masthead-mask {
  @apply opacity-100;
}
