.blacklabel {
  @apply bg-black px-12 py-6 text-10 text-white font-semibold tracking-widest uppercase border border-black;
}

.blacklabel--vertical {
  writing-mode: vertical-lr;
  @apply py-10 px-4;
}

.blacklabel--reversed {
  @apply bg-transparent text-black;
}

.blacklabel--link {
  @apply hover:text-black hover:bg-white active:text-white active:bg-purple-500;
}
