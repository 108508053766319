body {
  @apply antialiased bg-pale font-main text-base text-black;
}

img:not([src]):not([srcset]) {
  @apply invisible;
}

/* Hide IE banner by default */
.browser-upgrade {
  @apply hidden;
}

/* Styles for IE11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .browser-upgrade {
    @apply block;
  }
}

.browser-upgrade {
  @apply left-1/2 w-[90%] -translate-x-1/2;
}

.browser-upgrade__content {
  @apply border border-solid border-[#e21454] bg-white;
}

.browser-upgrade__content span {
  @apply text-[#e21454] font-semibold;
}

.browser-upgrade__content a,
.browser-upgrade__content .close--button {
  @apply hover:text-[#e21454];
}

.browser-upgrade__content:target {
  @apply hidden;
}

/* JS disabled alert */
.js-disabled__content:target {
  @apply hidden;
}

/* Remove default <summary> style  */
details > summary {
  @apply list-none;
}
details > summary::marker {
  @apply hidden;
}

/* Remove default <summary> arrow in Safari */
details summary::-webkit-details-marker {
  @apply hidden;
}

@layer base {
  .container {
    @apply mx-auto px-20 tablet:px-36 desktop:px-60 max-w-[1560px];
  }
}
