/* Company directions */

.cfg-company-directions-strip {
  @apply absolute opacity-0;
  transition: opacity 0.5s ease-in-out;
}

.cfg-company-directions-strip.chart--active {
  @apply opacity-100;
}
