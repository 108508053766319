.js-focus-visible .footer a:focus:not(.focus-visible),
.js-focus-visible .footer button:focus:not(.focus-visible),
.js-focus-visible .footer group:focus:not(.focus-visible) span {
  @apply outline-none;
}

/* Footer submit button */

.footer-submit-button {
  @apply border-purple-400 bg-purple-400 text-white hover:bg-white hover:text-black hover:border-white focus:outline-light active:border-white active:text-white active:bg-black-50 active:no-underline;
}
