/* Logo slider */

.logo-slider {
  @apply relative w-full grid;
}

.logo-slider__track {
  @apply flex items-center gap-36;
  animation: scroll 50s linear infinite;
  width: 1914px;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    /* 1/2 track + 1/2 of gap */
    transform: translateX(-987px);
  }
}

@screen tablet {
  .logo-slider {
    @apply overflow-hidden;
  }

  .logo-slider:before,
  .logo-slider:after {
    @apply absolute h-full z-20 pointer-events-none;
    background-image: linear-gradient(
      to right,
      rgba(254, 251, 249, 1) 0%,
      rgba(254, 251, 249, 0) 100%
    );
    content: "";
    width: 100px;
  }

  .logo-slider:before {
    @apply left-0 top-0;
  }

  .logo-slider:after {
    @apply right-0 top-0;
    transform: rotateZ(180deg);
  }

  .logo-slider__track {
    @apply gap-60;
    width: 2782px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      /* 1/2 track + 1/2 of gap */
      transform: translateX(-1421px);
    }
  }
}
