/* Select */
.ropi-calculator select {
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg height='9' viewBox='0 0 10 8' width='10' xmlns='http://www.w3.org/2000/svg' role='img' focusable='false' aria-hidden='aria_hidden'><path clip-rule='evenodd' d='m1.17917.844971 3.82083 3.901729 3.82083-3.901729 1.17917 1.204129-5 5.10587-5-5.10587z' fill-rule='evenodd'></path></svg>");
}

/* Range */
.ropi-calculator input[type="range"]::-webkit-slider-thumb {
  @apply rounded-full h-[18px] w-[18px] desktop:h-16 desktop:w-16 bg-purple-400 cursor-pointer appearance-none border-solid border-black;
}

.ropi-calculator input[type="range"]::-moz-range-thumb {
  @apply rounded-full h-[18px] w-[18px] desktop:h-16 desktop:w-16 bg-purple-400 cursor-pointer appearance-none border-solid border-black;
}

.ropi-calculator input[type="range"]::-ms-thumb {
  @apply rounded-full h-[18px] w-[18px] desktop:h-16 desktop:w-16 bg-purple-400 cursor-pointer appearance-none border-solid border-black;
}

/* Range value */
.ropi-calculator__range-value {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Tooltip */
.ropi-calculator__tooltip-wrapper {
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}
