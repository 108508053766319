/* Sticky nav */

.cfg-research-nav {
  @apply opacity-100 visible;
}

@keyframes cfgNavFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.cfg-progress-enso {
  @apply opacity-0 invisible;
}
.cfg-ca-logo {
  @apply block;
}

.cfg-research-nav--active .cfg-ca-logo {
  @apply hidden;
}

.cfg-research-nav--active .cfg-progress-enso {
  @apply opacity-100 visible;
  animation: cfgNavFadeIn 0.3s;
}

#enso-progress {
  stroke-dasharray: 500 500;
  stroke-dashoffset: 500;
}

.cfg-research-top__arrow {
  @apply hidden;
}

.cfg-research-top:hover .cfg-research-top__arrow {
  @apply block;
}

.cfg-research-top:hover .cfg-research-top__enso {
  @apply hidden;
}

/* Result drawer */

.results-drawer[open] summary {
  @apply border-b-2 border-black;
}

.results-drawer[open] summary .eyebrow {
  @apply font-extrabold;
}

.results-drawer__arrow {
  @apply hidden;
}

.results-drawer[open] .results-drawer__arrow {
  @apply block;
}

/* Sources information */

.sources-info-toggle--active svg {
  @apply rotate-180;
}

/* Workplace culture spinner */

.workplace-culture-spinner {
  animation: spin 20s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Uncertainties marquee */

.uncertainties-marquee {
  animation: uncertainties-marquee 45s linear infinite;
}

.uncertainties-marquee2 {
  animation: uncertainties-marquee2 45s linear infinite;
}

@keyframes uncertainties-marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes uncertainties-marquee2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

/*  Uncertainties and nutshell animations*/

.nutshell-item-1,
.nutshell-item-2,
.your-culture-determines-item-1,
.your-culture-determines-item-2,
.your-culture-determines-item-3,
.your-culture-determines-item-4 {
  @apply opacity-0;
  transition: opacity 0.5s ease-in;
}

.nutshell-item-2,
.your-culture-determines-item-2 {
  transition-delay: 0.5s;
}
.your-culture-determines-item-3 {
  transition-delay: 1s;
}
.your-culture-determines-item-4 {
  transition-delay: 1.5s;
}

.nutshell-animation--active .nutshell-item-1,
.nutshell-animation--active .nutshell-item-2,
.your-culture-determines-animation--active .your-culture-determines-item-1,
.your-culture-determines-animation--active .your-culture-determines-item-2,
.your-culture-determines-animation--active .your-culture-determines-item-3,
.your-culture-determines-animation--active .your-culture-determines-item-4 {
  @apply opacity-100;
}
