svg {
  @apply w-full h-full transition-colors duration-100;
}

svg path,
svg rect,
svg g {
  @apply fill-current;
}

/* 
  To do:
  Remove the above so it doesn't force fill-current on ALL svg elements site wide 
  When removed, note it will break most of our existing svg's and they'll need appropriate fills applied to each one eg. masthead and footer CA logos, icons etc.
*/

/* Wistia temp SVG fix */

.w-big-play-button rect {
  fill: none !important;
}
