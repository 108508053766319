.mask {
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.mask--1 {
  mask-image: url("media/masks/arch.svg");
}

.mask--2 {
  mask-image: url("media/masks/oval.svg");
}

.mask--3 {
  mask-image: url("media/masks/rectangle.svg");
}

.mask--4 {
  mask-image: url("media/masks/circle.svg");
}

.mask--5 {
  mask-image: url("media/masks/clover.svg");
}

.mask--6 {
  mask-image: url("media/masks/square-arch.svg");
}

.mask--7 {
  mask-image: url("media/masks/square-arch-right.svg");
}

.mask--8 {
  mask-image: url("media/masks/square-arch-left.svg");
}

.mask--9 {
  mask-image: url("media/masks/square.svg");
}
