.case-studies .component--hero:first-child + .component--logo-set {
  @apply -mt-12;
}

@screen tablet {
  .case-studies .component--hero:first-child + .component--logo-set {
    @apply -mt-24;
  }
}

@screen desktop {
  .case-studies .component--hero:first-child + .component--logo-set {
    @apply -mt-60;
  }
}
