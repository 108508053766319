/*
  Fields
*/

.hubspot-form .field {
  @apply mb-24 overflow-auto;
}

/*
  Labels
*/

.hubspot-form label span {
  @apply text-black text-14 normal-case mb-8 inline-block;
}

/*
  Inputs
*/

.hubspot-form .hs-input {
  @apply border border-white p-12 rounded-md text-14 bg-white w-full focus:outline-none focus:ring-2 focus:ring-black focus:ring-inset;
}

/* Inline intl and phone */

.hubspot-form .hs-fieldtype-phonenumber .hs-fieldtype-intl-phone {
  @apply flex;
}

.hs-input.hs-fieldtype-intl-phone {
  @apply border-none p-0;
}

.hubspot-form .hs-fieldtype-phonenumber .hs-fieldtype-intl-phone select {
  @apply mr-8;
}

.hubspot-form .hs-fieldtype-phonenumber .hs-fieldtype-intl-phone input {
  @apply pr-36 ml-8;
}

/* Select input */

.hubspot-form select.hs-input {
  @apply pr-36 appearance-none relative;
  -webkit-appearance: none;
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg height='9' viewBox='0 0 10 8' width='10' xmlns='http://www.w3.org/2000/svg' role='img' focusable='false' aria-hidden='aria_hidden'><path clip-rule='evenodd' d='m1.17917.844971 3.82083 3.901729 3.82083-3.901729 1.17917 1.204129-5 5.10587-5-5.10587z' fill-rule='evenodd'></path></svg>");
}

/* Text area input */

.hubspot-form textarea.hs-input {
  @apply h-auto;
}

/* Input error */

.hubspot-form .hs-input.invalid.error {
  @apply border-pink-500;
}

/*
  Error messages
*/

.hubspot-form .hs-error-msg {
  @apply text-pink-500 text-12 inline-block;
}

/* Last errors before submit */

.hubspot-form .hs_error_rollup {
  @apply text-pink-500 mb-24 text-12;
}

/*
  Multicontainer list items
*/

.hubspot-form .inputs-list.multi-container li {
  @apply mb-12;
}

.hubspot-form .inputs-list a {
  @apply underline focus:outline-dark;
}

/*
  Legend
*/

.hubspot-form legend {
  @apply mb-12;
}

.hubspot-form legend p {
  @apply mb-12;
}

/*
  Opt in
*/

.hubspot-form .hs_web_email_opt_in {
  @apply border-t border-black-30 pt-24;
}

.hubspot-form .hs_web_email_opt_in .inputs-list label,
.hubspot-form .hs_co_marketing_partner_opt_in .inputs-list label {
  @apply grid gap-x-12 items-center;
  grid-template-columns: auto 1fr;
}

/*
  ReCaptcha
*/

.hubspot-form .hs_recaptcha {
  @apply mb-24;
}

/*
  Checkbox
*/

.hubspot-form input[type="checkbox"] {
  @apply cursor-pointer h-24 w-24 inline-block align-middle float-left scale-100 appearance-none;
  -webkit-appearance: none;
}

.hubspot-form input[type="checkbox"]:checked:after {
  content: "\2713";
  @apply text-black absolute left-1/2 top-1/2;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hubspot-form input[type="checkbox"]:focus {
  @apply outline-none;
}

.hubspot-form .hs-form-checkbox-display,
.hubspot-form .hs-form-booleancheckbox-display {
  @apply grid gap-x-12 items-center;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
}

/* Label next to checkbox */
.hubspot-form input[type="checkbox"] + span {
  @apply text-12 float-left mb-0;
}

/*
  Submit button
*/

.hubspot-form .hs_submit input[type="submit"] {
  @apply cursor-pointer;
}

/*
  Form columns
*/

.hubspot-form .form-columns-2 {
  @apply flex;
}

.hubspot-form .form-columns-2 .hs-form-field {
  @apply w-1/2;
}

.hubspot-form .form-columns-2 .field:first-child {
  @apply pr-8;
}

.hubspot-form .form-columns-2 .field:nth-child(2) {
  @apply pl-8;
}

/* 
  Rich text
*/

.hubspot-form .hs-richtext p {
  @apply text-12 mb-20;
}

.hubspot-form .hs-richtext p a {
  @apply underline;
}

/* Inputs list */

.hubspot-form .inputs-list {
  @apply text-12 mt-10;
}

.hubspot-form .inputs-list a {
  @apply cursor-pointer;
}
