/* 
  Modifier classes
*/

/* White errors */
.hubspot-form--darkmode .hs-error-msg,
.hubspot-form--darkmode .hs_error_rollup {
  @apply text-white;
}

/* White labels */
.hubspot-form--darkmode .hs-form-field > label span {
  @apply text-white;
}

/* Adds borders to fields */
.hubspot-form--field-borders .hs-input {
  @apply border;
  @apply border-black;
}

.hubspot-form--field-borders .hs-input.invalid.error {
  @apply border-pink-500;
}
