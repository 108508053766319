.cfg-region-select {
  @apply appearance-none pl-[18px] pr-36 py-12 rounded-md font-main text-14 inline-flex items-center justify-center transition-colors duration-100 w-auto bg-white text-black border border-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-inset bg-no-repeat bg-[right_18px_center];
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg height='9' viewBox='0 0 10 8' width='10' xmlns='http://www.w3.org/2000/svg' role='img' focusable='false' aria-hidden='aria_hidden'><path clip-rule='evenodd' d='m1.17917.844971 3.82083 3.901729 3.82083-3.901729 1.17917 1.204129-5 5.10587-5-5.10587z' fill-rule='evenodd'></path></svg>");
}

/* Flippin word */
.cfg-flipping-word {
  perspective: 1000px;
}

.cfg-word {
  display: inline-block;
  backface-visibility: hidden;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.cfg-word.flip-out {
  animation-name: flip-out;
}

.cfg-word.flip-in {
  animation-name: flip-in;
}

@keyframes flip-in {
  0% {
    transform: rotateX(-95deg);
  }

  100% {
    transform: rotateX(0);
  }
}

@keyframes flip-out {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(95deg);
  }
}

@keyframes flipping-word {
  0% {
    transform: rotateX(-95deg);
  }

  10%,
  90% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(95deg);
  }
}

/* Wistia - overrides to reducing sound button size and svg colour issue */
.cfg-wistia-embed .click-for-sound-btn svg {
  @apply h-[65px] w-[65px] text-white;
}

.cfg-wistia-embed .click-for-sound-btn path {
  fill: white;
}

.cfg-wistia-embed .click-for-sound-btn g path {
  fill: none;
}

/* Footer */
.cfg-footer {
  @apply bg-repeat-x bg-top bg-contain desktop:bg-auto;
  background-image: url("media/campaigns/culture-first-global/2024/footer-pattern.webp");
}

/* White ribbon slider  */
@-webkit-keyframes ribbon-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-2880px * 1));
  }
}
@keyframes ribbon-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-2880px * 1));
  }
}

.cfg-ribbon-slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.cfg-ribbon-slider .cfg-ribbon-slide-track {
  -webkit-animation: ribbon-scroll 100s linear infinite;
  animation: ribbon-scroll 100s linear infinite;
  display: flex;
  width: calc(2880px * 2);
}

.cfg-ribbon-slider .cfg-ribbon-slide {
  height: 70px;
  width: 2880px;
}

/* Testimonial marquee */
@screen tablet {
  .cfg23-testimonial-marquee__content {
    animation: cfg23-testimonial-marquee 90s linear infinite;
  }

  .cfg23-testimonial-marquee:hover .cfg23-testimonial-marquee__content {
    animation-play-state: paused;
  }

  @keyframes cfg23-testimonial-marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-4804px - 24px));
    }
  }

  /* Pause animation when reduced-motion is set */
  @media (prefers-reduced-motion: reduce) {
    .cfg23-testimonial-marquee__content {
      animation-play-state: paused;
    }
  }
}

/* Swiper */
.cfg-keynote-swiper .swiper-pagination-bullet {
  @apply inline-block rounded-full bg-black-30 size-10 desktop:size-16 cursor-pointer;
}

.cfg-keynote-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-black;
}

.cfg-keynote-swiper .keynote-bio {
  @apply opacity-0 transition-opacity;
}

.cfg-keynote-swiper .cfg-active-keynote .keynote-bio {
  @apply opacity-100;
}
