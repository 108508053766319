.transcript-accordion__summary {
  @apply list-none;
}

.transcript-accordion__summary::-webkit-details-marker,
.transcript-accordion__summary::marker {
  @apply hidden;
}

.transcript-accordion__details[open] .transcript-accordion__summary svg {
  @apply first-of-type:hidden;
}

.transcript-accordion__details[open] .transcript-accordion__summary svg {
  @apply last-of-type:block;
}
