/* Carousel pagination */
.carousel-pagination .swiper-pagination-bullets {
  @apply flex gap-12;
}
.carousel-pagination .swiper-pagination-bullet {
  @apply block w-8 h-8 rounded-full bg-black-10 cursor-pointer;
}
.carousel-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-black;
}

/* Reversed carousel pagination */
.carousel-pagination--reversed .swiper-pagination-bullet {
  @apply bg-white-10;
}
.carousel-pagination--reversed
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-white;
}

/* Carousel tabs */
.carousel-tabs .swiper-slide {
  @apply w-auto text-black-50 hover:text-black cursor-pointer;
}
.carousel-tabs .swiper-slide.swiper-slide-thumb-active {
  @apply border-b-2 border-black text-black;
}

/* Reversed carousel tabs */
.carousel-tabs--reversed .swiper-slide {
  @apply text-white-50 hover:text-white;
}
.carousel-tabs--reversed .swiper-slide.swiper-slide-thumb-active {
  @apply border-white text-white;
}

.carousel .swiper {
  margin-left: initial;
  margin-right: initial;
}

/* Carousel fade */
.carousel-main--fade {
  @apply !px-20 !-mx-20 tablet:!px-132 tablet:!-mx-132 tablet:carousel-main--fade-mask;
}

@layer utilities {
  .carousel-main--fade-mask {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 132px,
      rgba(0, 0, 0, 1) calc(100% - 132px),
      rgba(0, 0, 0, 0) calc(100%)
    );
  }
}
