/* Hide play icon as bg video auto plays */
.background-video-toggle__play-icon {
  @apply hidden;
}

/* Hide pause icon when video is paused */
.background-video-toggle--paused .background-video-toggle__pause-icon {
  @apply hidden;
}

/* Show play icon when video is paused */
.background-video-toggle--paused .background-video-toggle__play-icon {
  @apply block;
}
