.hrforhr-flash-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1001;
  top: 20px;
}

.hrforhr-flash {
  border-radius: 3px;
  display: flex;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  max-width: 500px;
  padding: 20px 20px 20px 25px;
}

.hrforhr-flash--alert {
  background-color: #fffae8;
  background-color: rgba(255, 250, 232, 1);
}

.hrforhr-flash--alert,
.hrforhr-flash--alert g {
  background-color: #fff5d2;
  background-color: rgba(255, 245, 210, 1);
}

.hrforhr-flash--notice {
  background-color: #e9f6f3;
  background-color: rgba(233, 246, 243, 1);
}

.hrforhr-flash--notice,
.hrforhr-flash--notice g {
  background-color: #bce3dc;
  background-color: rgba(188, 227, 220, 1);
}

.hrforhr-flash p {
  flex: 1;
  margin-bottom: 2px;
}

.hrforhr-flash__dismiss {
  background: none;
  border: none;
  cursor: pointer;
  margin: -18px -20px -20px;
  padding: 20px;
}
