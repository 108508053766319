.swiper-wrapper--marquee {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  align-items: center;
}

.marquee-main .swiper-slide {
  width: auto;
}

@screen desktop {
  .marquee .fade {
    --mask-offset: 3rem;
    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) calc(0% + var(--mask-offset)),
      rgba(0, 0, 0, 1) calc(100% - var(--mask-offset)),
      rgba(0, 0, 0, 0) 100%
    );
  }
}

/* Accessibility Updates */

@media (prefers-reduced-motion) {
  .swiper-wrapper--marquee {
    margin: auto;
    max-width: fit-content;
  }
}
