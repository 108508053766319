#dot-solid-1 {
  will-change: transform;
}

#dot-solid-2 {
  will-change: transform;
}

#dot-solid-3 {
  will-change: transform;
}

#dot-solid-4 {
  will-change: transform;
}

#dot-solid-5 {
  will-change: transform;
}

#dot-solid-6 {
  will-change: transform;
}

#dot-solid-7 {
  will-change: transform;
}

#dot-solid-8 {
  will-change: transform;
}

#dot-solid-9 {
  will-change: transform;
}

.story-animation-one {
  transform-origin: center center;
}

#outer-ring,
#second-ring,
#animation {
  will-change: transform;
  transform-origin: center center;
}

#outer-ring,
#second-ring {
  @apply hidden;
}

.cfg-story--pulse #outer-ring,
.cfg-story--pulse #second-ring {
  @apply block;
}

#second-ring {
  animation: pulse1 1s ease-in-out infinite;
}

@keyframes pulse1 {
  0% {
    transform: scale(0.75, 0.75);
    opacity: 0;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    transform: scale(1.35, 1.35);
    opacity: 0;
  }
}

#outer-ring {
  animation: pulse2 1s ease-in-out infinite;
  animation-delay: 0.1s;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.75, 0.75);
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
