/* Colored Card Cycling */

:nth-child(4n + 1 of .card-colored.card-colored--cycle),
:nth-child(4n + 1 of li) > .card-colored.card-colored--cycle {
  @apply bg-green-300;
}

:nth-child(4n + 2 of .card-colored.card-colored--cycle),
:nth-child(4n + 2 of li) > .card-colored.card-colored--cycle {
  @apply bg-purple-200;
}

:nth-child(4n + 3 of .card-colored.card-colored--cycle),
:nth-child(4n + 3 of li) > .card-colored.card-colored--cycle {
  @apply bg-orange-300;
}

:nth-child(4n + 4 of .card-colored.card-colored--cycle),
:nth-child(4n + 4 of li) > .card-colored.card-colored--cycle {
  @apply bg-blue-200;
}
