@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(77px * 25));
  }
}

@screen desktop {
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(153px * 25));
    }
  }
}

.ecc-slide-track {
  animation: scroll 90s linear infinite;
}

.ecc-slide-track:hover {
  animation-play-state: paused;
}
