/*
  Copy
*/

/* Paragraphs */

.copy > p {
  @apply pb-[1.5em] last:pb-0 empty:hidden;
}

/* Headings */

.copy > h2,
.copy > h3,
.copy > h4 {
  @apply font-main mb-20 tablet:mb-24 font-medium scroll-mt-84;
}

.copy > h2 {
  @apply text-24 tablet:text-32;
}

.copy > h3 {
  @apply text-20 tablet:text-24;
}

.copy > h4 {
  @apply text-16 tablet:text-20 font-bold tablet:font-medium;
}

/* Top padding */

.copy > p + h2,
.copy > p + h3,
.copy > p + h4,
.copy > ul + h2,
.copy > ul + h3,
.copy > ul + h4,
.copy > ol + h2,
.copy > ol + h3,
.copy > ol + h4,
.copy > blockquote + h2,
.copy > blockquote + h3,
.copy > blockquote + h4 {
  @apply mt-12;
}

/* Lists */

.copy > ul,
.copy > ol {
  @apply mb-24 last:mb-0 ml-16 tablet:ml-20 desktop:ml-24;
}

.copy > ul {
  @apply list-disc;
}

.copy > ol {
  @apply list-decimal;
}

.copy > ul li,
.copy > ol li {
  @apply mb-12 last:mb-0;
}
/* Bold */

.copy > strong {
  @apply font-bold;
}

/* Italic */

.copy > em {
  @apply italic;
}

/* Links */

.copy > p a,
.copy > ul li a,
.copy > ol li a,
.copy > blockquote a,
.copy > h2 a,
.copy > h3 a,
.copy table a {
  @apply text-link;
}

/* Reversed (see color_theme.rb) */

.copy--reversed {
  @apply text-white;
}

.copy--reversed > p a,
.copy--reversed > ul li a,
.copy--reversed > ol li a,
.copy--reversed > blockquote a,
.copy--reversed > h2 a,
.copy--reversed > h3 a,
.copy--reversed table a {
  @apply hover:bg-white-10;
}

/* Block quotes */

.copy > blockquote {
  @apply py-12 px-36 mb-[1.5em] last:mb-0 border-l-2 border-black;
}

/* Pull quote (not rich text component) */
.copy > aside {
  @apply text-24 tablet:text-32 font-heading mb-[1.5em];
}

/* Horizontal rules */

.copy > hr {
  @apply block border-t-0 border-b border-black-30 h-px mb-[1.5em] last:mb-0;
}

/* Tables */

.copy table {
  @apply table-auto w-full;
}

.copy th {
  @apply text-left font-medium p-12 align-baseline border-b-2 border-black;
}

.copy td {
  @apply border-b border-black-30 p-12;
}

/* Modifier for tables that need to be wider */

@screen desktop {
  .copy .table--wide {
    @apply ml-[-15rem];
    width: calc(100% + 30rem);
  }
}
