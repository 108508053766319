@font-face {
  font-family: "inter";
  font-weight: 400;
  font-display: swap;
  src:
    url("./core/fonts/inter/inter-regular.woff2") format("woff2"),
    url("./core/fonts/inter/inter-regular.woff") format("woff");
}

@font-face {
  font-family: "inter";
  font-weight: 500;
  font-display: swap;
  src:
    url("./core/fonts/inter/inter-medium.woff2") format("woff2"),
    url("./core/fonts/inter/inter-medium.woff") format("woff");
}

@font-face {
  font-family: "inter";
  font-weight: 600;
  font-display: swap;
  src:
    url("./core/fonts/inter/inter-semi-bold.woff2") format("woff2"),
    url("./core/fonts/inter/inter-semi-bold.woff") format("woff");
}

@font-face {
  font-family: "inter";
  font-weight: 800;
  font-display: swap;
  src:
    url("./core/fonts/inter/inter-bold.woff2") format("woff2"),
    url("./core/fonts/inter/inter-bold.woff") format("woff");
}
