/* Table element */
.comparison-chart {
  @apply text-14 mx-auto w-full;
}

.comparison-chart--large {
  @apply border-b-0 border-t-0;
}

/* th elements */

.comparison-chart th {
  @apply p-24 align-middle;
}

/* Logos */

.comparison-chart__logo {
  @apply max-w-84 max-h-48;
}

@screen tablet {
  .comparison-chart__logo {
    @apply max-w-108;
  }
}

/* Cells */

.comparison-chart td {
  @apply py-16;
}

.comparison-chart td:nth-of-type(2) {
  @apply border-l border-r border-black border-b-0;
}

.comparison-chart tr:last-child td:nth-of-type(2) {
  @apply border-l border-r border-b;
}

.comparison-chart__row-label {
  @apply pr-24 w-full;
}

/* Icons */

.comparison-chart__icon {
  @apply mx-auto w-16 h-16;
}

@screen tablet {
  .comparison-chart__icon {
    @apply w-20 h-20;
  }
}
