@layer utilities {
  .outline-none {
    outline: none;
  }
  .outline-dark {
    outline: 1px solid #000000;
    outline-offset: 4px;
  }
  .outline-light {
    outline: 1px solid #ffffff;
    outline-offset: 4px;
  }
}
